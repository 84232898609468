import React from "react";
import TeamLogo from "./TeamLogo";

function FavoriteTeam({ teams, selectedTeam, onTeamSelect }) {
  return (
    <div className="container">
      <h3 className="text-center mb-4 alt-font">
        Which Fanbase Do You Belong To?
      </h3>
      <div className="row justify-content-center">
        {teams.map((team) => (
          <div className="col-3 col-md-2 mb-3 override-margin" key={team.id}>
            <TeamLogo
              team={team}
              isSelected={selectedTeam === team.id}
              onClick={onTeamSelect}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default FavoriteTeam;
