// App.js
import React, { useState, useEffect } from "react";
import FavoriteTeam from "./FavoriteTeam";
import ChampPicks from "./ChampPicks";
import EmailForm from "./EmailForm";
import Celebration from "./Celebration";
import TournamentPicks from "./TournamentPicks";
import About from "./About";
import TransitionScreen from "./TransitionScreen";
import Footer from "./Footer";
import AdminPanel from "./AdminPanel";
import { secTeams } from "./data";
import "./App.css";
import { Button } from "react-bootstrap";
import logo from "./img/text_logo_trans.png";
import { auth, db } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";

function App() {
  const [step, setStep] = useState(1);
  const [favoriteTeam, setFavoriteTeam] = useState(null);
  const [ChampTeams, setChampTeams] = useState([]);
  const [showAbout, setShowAbout] = useState(false);
  const [showAdminPanel, setShowAdminPanel] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState(null);
  const [loadingAuth, setLoadingAuth] = useState(true); // Loading state for auth
  const [appLoaded, setAppLoaded] = useState(false); // New state for app load

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setLoadingAuth(true); // Start loading
      if (currentUser) {
        setUser(currentUser);
        // Fetch user data and check for admin status by querying for the email
        try {
          const usersRef = collection(db, "users");
          const q = query(usersRef, where("email", "==", currentUser.email));
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            // User with that email exists.  Check for admin status.
            // There should only be one, but we use forEach to be safe.
            querySnapshot.forEach((doc) => {
              if (doc.data().admin) {
                setIsAdmin(true);
              } else {
                setIsAdmin(false);
              }
            });
          } else {
            setIsAdmin(false);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          setIsAdmin(false); // Default to non-admin on error
        }
      } else {
        setUser(null);
        setIsAdmin(false);
      }
      setLoadingAuth(false); // Done loading auth
      setAppLoaded(true); // App is loaded after auth check
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  useEffect(() => {
    // Hide the loading screen *after* App.js is fully loaded and auth is checked
    if (appLoaded) {
      const loadingElement = document.getElementById("loading");
      if (loadingElement) {
        loadingElement.style.display = "none";
      }
    }
  }, [appLoaded]); // Run this effect when appLoaded changes

  const handleFavoriteTeamSelect = (teamId) => {
    setFavoriteTeam(teamId);
    setStep(2.5);
  };

  const handleTransition = () => {
    setStep(2);
  };

  const handleChampionSelect = (teamId) => {
    const isSelected = ChampTeams.includes(teamId);
    let updatedSelections;

    if (isSelected) {
      updatedSelections = ChampTeams.filter((id) => id !== teamId);
    } else {
      updatedSelections =
        ChampTeams.length < 4 ? [...ChampTeams, teamId] : ChampTeams;
    }
    setChampTeams(updatedSelections);

    if (updatedSelections.length === 4) {
      setStep(3);
    }
  };

  const handleEmailSubmit = (email) => {
    console.log("Favorite Team:", favoriteTeam);
    console.log("Final Four:", ChampTeams);
    console.log("Email:", email);
    setStep(4);
  };

  const resetAppState = () => {
    setStep(1);
    setFavoriteTeam(null);
    setChampTeams([]);
  };

    if (!appLoaded) {
        return null; // Render nothing while loading
    }

  return (
    <div className="app">
      <header className="app-header marker-font">
        <button className="header-logo-button" onClick={resetAppState}>
          <img src={logo} alt="Logo" className="header-logo" />
        </button>
        <Button
          variant="dark"
          className="about-button"
          onClick={() => setShowAbout(true)}
        >
          ℹ️
        </Button>
      </header>

      <main>
        <div className="background-image"></div>
        <div className="overlay-card">
          {step === 1 && (
            <div>
              <h4 className="alt-font text-center">
                Which SEC fanbase has the highest Basketball IQ?
              </h4>
              <br />
              <p className="text-center">🏀 Declare your loyalty</p>
              <p className="text-center">🏀 make your 4 picks</p>
              <p className="text-center">🏀 may the best fanbase win!</p>
              <br />
              <button className="start-button" onClick={() => setStep(1.5)}>
                Make Your Picks
              </button>
            </div>
          )}

          {step === 1.5 && (
            <FavoriteTeam
              teams={secTeams}
              selectedTeam={favoriteTeam}
              onTeamSelect={handleFavoriteTeamSelect}
            />
          )}

          {step === 2.5 && (
            <TransitionScreen
              // message="Now pick the teams that will make the SEC Final Four!"
              onTransition={handleTransition}
            />
          )}

          {step === 2 && (
            <ChampPicks
              teams={secTeams}
              selectedTeams={ChampTeams}
              onTeamSelect={handleChampionSelect}
            />
          )}

          {step === 3 && (
            <EmailForm
              onSubmit={handleEmailSubmit}
              favoriteTeam={favoriteTeam}
              ChampTeams={ChampTeams}
            />
          )}

          {step === 4 && <Celebration />}
        </div>
        <TournamentPicks />
      </main>
      <Footer
        onAboutClick={() => setShowAbout(true)}
        onAdminClick={() => setShowAdminPanel(true)}
      />
      {showAbout && (
        <About show={showAbout} onHide={() => setShowAbout(false)} />
      )}
      {showAdminPanel && (
        <AdminPanel
          show={showAdminPanel}
          onClose={() => setShowAdminPanel(false)}
          user={user}
          isAdmin={isAdmin}
        />
      )}
    </div>
  );
}

export default App;
