import React, { useState, useEffect } from "react";

function TransitionScreen({ message, onTransition }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      onTransition();
    }, 500);

    return () => clearTimeout(timer);
  }, [onTransition]);

  return (
    <div className="transition-screen">
      {/* <h2 className="alt-font">{message}</h2> */}
      {loading && <div className="loader"></div>}
    </div>
  );
}

export default TransitionScreen;