import React, { useState, useEffect } from "react";
import { db } from "./firebase";
import { collection, query, orderBy, limit, getDocs } from "firebase/firestore";
import { Card, Table } from "react-bootstrap";
import { secTeams } from "./data";

const TournamentPicks = () => {
  const [teamStatus, setTeamStatus] = useState(null);
  const [basketballPicks, setBasketballPicks] = useState(null);
  const [fanbaseStandings, setFanbaseStandings] = useState([]);

  const getTeamLogo = (teamName) => {
    const team = secTeams.find(
      (t) => t.id.toLowerCase() === teamName.toLowerCase()
    );
    return team.logo; // Fallback if not found
  };

  useEffect(() => {
    const fetchTeamStatus = async () => {
      const teamStatusQuery = query(
        collection(db, "basketballTeamStatus"),
        orderBy("createdAt", "desc"),
        limit(1)
      );
      const teamStatusSnapshot = await getDocs(teamStatusQuery);
      if (!teamStatusSnapshot.empty) {
        setTeamStatus(teamStatusSnapshot.docs[0].data());
      }
      console.log(
        "Team Status Snapshot: ",
        teamStatusSnapshot.docs.map((doc) => doc.data())
      );
    };

    const fetchBasketballPicks = async () => {
      const picksQuery = query(
        collection(db, "basketballPicks"),
        orderBy("createdAt", "desc"),
        limit(1)
      );
      const picksSnapshot = await getDocs(picksQuery);
      if (!picksSnapshot.empty) {
        setBasketballPicks(picksSnapshot.docs[0].data());
      }
      console.log(
        "Basketball Picks Snapshot: ",
        picksSnapshot.docs.map((doc) => doc.data())
      );
    };

    fetchTeamStatus();
    fetchBasketballPicks();
  }, []);

  useEffect(() => {
    if (!teamStatus || !basketballPicks) return;

    const activeTeams = Object.keys(teamStatus).filter(
      (team) => teamStatus[team]
    );
    const standings = [];

    Object.keys(basketballPicks).forEach((fanbase) => {
      if (fanbase === "createdAt") return;

      const votes = basketballPicks[fanbase];
      const totalVotes = Object.values(votes).reduce(
        (sum, count) => sum + count,
        0
      );

      const activeVotes = Object.keys(votes).reduce((sum, team) => {
        return activeTeams.includes(team) ? sum + votes[team] : sum;
      }, 0);

      const activeRatio = totalVotes > 0 ? activeVotes / totalVotes : 0; // Avoid division by zero

      const teamRatios = Object.keys(votes).map((team) => ({
        team,
        ratio: votes[team] / totalVotes,
        active: activeTeams.includes(team),
      }));

      const topPicks = teamRatios.sort((a, b) => b.ratio - a.ratio).slice(0, 2);

      standings.push({
        fanbase,
        topPicks,
        activeRatio: activeRatio,
      });
    });

    standings.sort((a, b) => b.activeRatio - a.activeRatio);
    setFanbaseStandings(standings);
  }, [teamStatus, basketballPicks]);

  return (
    <Card className="results-card">
      <Card.Body>
        <Card.Title className="text-center alt-font">
          <h2>
            <b>Current Standings</b>
          </h2>
        </Card.Title>
        <Table striped bordered hover variant="dark">
          <thead>
            <tr>
              <th style={{ width: "30%", color: "white" }}>Fanbase</th>
              <th style={{ width: "30%", color: "white" }}>Accuracy</th>
              <th style={{ width: "20%", color: "white" }}>Top Pick</th>
              <th style={{ width: "20%", color: "white" }}>2nd Pick</th>
            </tr>
          </thead>
          <tbody>
            {fanbaseStandings.map((fanbaseData, index) => (
              <tr key={index}>
                {/* Fanbase */}
                <td style={{ textAlign: "center" }}>
                  <img
                    src={getTeamLogo(fanbaseData.fanbase)}
                    alt={fanbaseData.fanbase}
                    style={{ width: "60px", height: "60px" }}
                  />
                </td>

                {/* Accuracy */}
                <td style={{ textAlign: "center", verticalAlign: "middle" }}>
                  {(fanbaseData.activeRatio * 100).toFixed(1)}%
                </td>

                {/* Display Team Logos with Percentages Underneath */}
                {fanbaseData.topPicks.map((pick, pickIndex) => (
                  <td
                    key={pickIndex}
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <img
                      src={getTeamLogo(pick.team)}
                      alt={pick.team}
                      style={{
                        width: "45px",
                        height: "45px",
                        display: "block",
                        margin: "auto",
                      }}
                    />
                    <div
                      style={{
                        fontSize: "14px",
                        marginTop: "5px",
                        verticalAlign: "middle",
                      }}
                    >
                      {(pick.ratio * 100).toFixed(1)}%
                    </div>
                  </td>
                ))}

                {/* Ensure Two Columns for Teams Even if Only One is Available */}
                {fanbaseData.topPicks.length === 1 && (
                  <td style={{ textAlign: "center" }}></td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default TournamentPicks;
