import React from "react";

function TeamLogo({ team, isSelected, onClick }) {
  return (
    <img
      src={team.logo}
      alt={team.name}
      className={`team-logo ${isSelected ? "selected" : ""}`}
      onClick={() => onClick(team.id)}
    />
  );
}

export default TeamLogo;
