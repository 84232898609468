import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import {
  collection,
  addDoc,
  serverTimestamp,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import PropTypes from "prop-types";
import { secTeams } from "./data";

function EmailForm({ onSubmit, favoriteTeam, ChampTeams }) {
  const [email, setEmail] = useState("");
  const [consent, setConsent] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email || !consent) {
      alert("Please enter your email and agree to the terms.");
      return;
    }

    try {
      const emailCollection = collection(db, "basketballEmails");
      const emailQuery = query(emailCollection, where("email", "==", email));
      const querySnapshot = await getDocs(emailQuery);

      if (!querySnapshot.empty) {
        alert("This email has already been submitted.");
        return;
      }

      const newSubmission = {
        createdAt: serverTimestamp(),
        email: email,
        fanTeam: favoriteTeam,
        optIn: consent ? "yes" : "no",
        team1: ChampTeams[0], 
        team2: ChampTeams[1],
        team3: ChampTeams[2], 
        team4: ChampTeams[3],
      };

      await addDoc(emailCollection, newSubmission);

      onSubmit(email);
      setEmail("");
      setConsent(false);
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("There was an error with your submission. Please try again.");
    }
  };

  const getTeamLogo = (teamId) => {
    const team = secTeams.find((t) => t.id === teamId);
    return team ? team.logo : null;
  };

  return (
    <div className="container">
      <h2 className="text-center mb-4">My Picks</h2>

      <div className="d-flex justify-content-center mb-3">
        {ChampTeams &&
          ChampTeams.length > 0 &&
          ChampTeams.slice(0, 4).map((teamId) => {
            // Conditional rendering here
            const logo = getTeamLogo(teamId);
            return logo ? (
              <img
                key={teamId}
                src={logo}
                alt={`${teamId} Logo`}
                style={{ width: "70px", height: "70px", margin: "0 10px" }}
              />
            ) : null;
          })}
      </div>
      <br />
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>

        <Form.Group className="mb-3 alt-font" controlId="formConsent">
          <Form.Check
            type="checkbox"
            label="I'm fine with a few quick email updates."
            checked={consent}
            onChange={(e) => setConsent(e.target.checked)}
            required
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Submit My Picks
        </Button>
      </Form>
    </div>
  );
}

EmailForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  favoriteTeam: PropTypes.string,
  ChampTeams: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default EmailForm;
