export const secTeams = [
  { id: "alabama", name: "Alabama", logo: "/logos/al.png" },
  { id: "arkansas", name: "Arkansas", logo: "/logos/ark.png" },
  { id: "auburn", name: "Auburn", logo: "/logos/aub.png" },
  { id: "florida", name: "Florida", logo: "/logos/fl.png" },
  { id: "georgia", name: "Georgia", logo: "/logos/ga.png" },
  { id: "kentucky", name: "Kentucky", logo: "/logos/ky.png" },
  { id: "lsu", name: "LSU", logo: "/logos/last.png" },
  { id: "msstate", name: "Miss State", logo: "/logos/msst.png" },
  { id: "missouri", name: "Missouri", logo: "/logos/mo.png" },
  { id: "oklahoma", name: "Oklahoma", logo: "/logos/ok.png" },
  { id: "olemiss", name: "Ole Miss", logo: "/logos/miss.png" },
  { id: "sc", name: "South Carolina", logo: "/logos/sc.png" },
  { id: "tennessee", name: "Tennessee", logo: "/logos/tenn.png" },
  { id: "texas", name: "Texas", logo: "/logos/tex.png" },
  { id: "texasam", name: "Texas A&M", logo: "/logos/txam.png" },
  { id: "vanderbilt", name: "Vanderbilt", logo: "/logos/nash.png" },
];
