import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAPvXG0kSdRrzIDw4bT8gEgqPizyl0ZGjs",
  authDomain: "sec-fantasy.firebaseapp.com",
  projectId: "sec-fantasy",
  storageBucket: "sec-fantasy.appspot.com",
  messagingSenderId: "719222739809",
  appId: "1:719222739809:web:28f09630eff2109d91ab20",
  measurementId: "G-V5M0PWKCXX",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Function to handle Google Sign-In
const signIn = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    return result.user;
  } catch (error) {
    console.error("Error signing in:", error);
    return null;
  }
};

// Function to handle Sign-Out
const signOutUser = async () => {
  try {
    await signOut(auth);
  } catch (error) {
    console.error("Error signing out:", error);
  }
};

export { auth, db, signIn, signOutUser };