import React from "react";
import "./App.css";
import logo from "./img/text_logo_trans.png";
import { Modal, Button, Card } from "react-bootstrap";
import { signIn, signOutUser } from "./firebase";

function AdminPanel({ show, onClose, user, isAdmin }) {
  const handleSignIn = async () => {
    try {
      await signIn(); // This will trigger the onAuthStateChanged in App.js
    } catch (error) {
      console.error("Sign-in error:", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOutUser();
    } catch (error) {
      console.error("Sign-out error:", error);
    }
  };

  const placeholder = () => {
    // this will be replaced by Firebase functions
    console.log('placeholder')
  }

  return (
    <Modal show={show} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="marker-font">
          <span style={{ marginRight: "20px" }}>
            <img
              src={logo}
              alt="Logo"
              className="inline-logo"
              style={{ marginLeft: "120px" }} // Keep consistent styling
            />
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Card>
          <Card.Body>
            {user ? (
              isAdmin ? (
                <>
                  <p className="marker-font text-center">Admin Controls</p>
                  <p className="marker-font text-center">
                    <Button variant="primary" onClick={placeholder}>
                      Initialize Picks to 0
                    </Button>
                  </p>
                  <p className="marker-font text-center">
                    <Button variant="primary" onClick={placeholder}>
                      Update Picks Now
                    </Button>
                  </p>
                  <p className="marker-font text-center">
                    <Button variant="danger" onClick={handleSignOut}>
                      Sign Out
                    </Button>
                  </p>
                  <p className="marker-font text-center">
                    <Button variant="outline-primary" onClick={onClose}>
                      Close
                    </Button>
                  </p>
                </>
              ) : (
                <>
                  <p className="marker-font text-center">
                    You are not an admin.
                  </p>
                  <p className="marker-font text-center">
                    <Button variant="danger" onClick={handleSignOut}>
                      Sign Out
                    </Button>
                  </p>
                  <p className="marker-font text-center">
                    <Button variant="outline-primary" onClick={onClose}>
                      Close
                    </Button>
                  </p>
                </>
              )
            ) : (
              <p className="marker-font text-center">
                <Button variant="outline-primary" onClick={handleSignIn}>
                  Sign in with Google
                </Button>
              </p>
            )}
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
}

export default AdminPanel;
