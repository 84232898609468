import React from "react";

function Celebration() {
  return (
    <div className="container">
      <h1 className="text-center mt-4">Picks Complete!</h1>
      <br />
      <p className="text-center">🏀 🏀 🏀</p>
      <p className="text-center alt-font">
        Stay tuned to see if your fanbase has the highest basketball IQ!
      </p>
      <div className="text-center"></div>
    </div>
  );
}

export default Celebration;
