import React, { useState } from "react";
import TeamLogo from "./TeamLogo";

function ChampPicks({ teams, selectedTeams, onTeamSelect }) {
  const [localSelectedTeams, setLocalSelectedTeams] = useState(selectedTeams || []); // Initialize

  const handleTeamSelect = (teamId) => {
    onTeamSelect(teamId); // Keep this to update the parent if needed

    const isSelected = localSelectedTeams.includes(teamId); // Use local state
    let updatedSelections;

    if (isSelected) {
      updatedSelections = localSelectedTeams.filter((id) => id!== teamId);
    } else {
      updatedSelections =
        localSelectedTeams.length < 4
        ? [...localSelectedTeams, teamId]
        : localSelectedTeams;
    }

    setLocalSelectedTeams(updatedSelections);
  };

  return (
    <div className="container">
      <h3 className="text-center mb-4">
        Which 4 Teams Will Make
        <br />
        the SEC Championship?
      </h3>
      <div className="row justify-content-center">
        {teams.map((team) => (
          <div className="col-3 col-md-2 mb-3 override-margin" key={team.id}>
            <TeamLogo
              team={team}
              isSelected={localSelectedTeams.includes(team.id)} // Use local state
              onClick={() => handleTeamSelect(team.id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ChampPicks;
