import React from "react";
import "./App.css";
import logo from "./img/text_logo_trans.png";
import { Button } from "react-bootstrap";

function Footer({ onAboutClick, onAdminClick }) {
  return (
    <footer className="app-footer">
      <div className="footer-content">
        <p>
          <span style={{ marginRight: "20px" }}>
            <img src={logo} alt="Logo" className="inline-logo" />
          </span>
          <span style={{ marginRight: "20px" }} className="marker-font">
            <Button
              variant="dark"
              onClick={onAboutClick}
              className="marker-font"
            >
              About
            </Button>
          </span>
          &copy; <span className="alt-font">{new Date().getFullYear()}</span>
          <span className="clickable ms-3" onClick={onAdminClick}>
            ⚙️
          </span>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
