import React, { useState } from "react";
import "./App.css";
import logo from "./img/text_logo_trans.png";
import { Modal, Button, Form, Card } from "react-bootstrap";
import { db } from "./firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

function About({ show, onHide }) {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    // Make the function async
    e.preventDefault();

    try {
      // Add a new document with a generated id to the "footballSubmissionEmails" collection
      await addDoc(collection(db, "footballSubscribeEmails"), {
        email: email,
        createdAt: serverTimestamp(), // Use serverTimestamp() for accurate timestamps
      });

      console.log("Subscribed with email:", email);
      setSubscribed(true);

      setTimeout(() => {
        setSubscribed(false);
        setEmail("");
        onHide();
      }, 3000);
    } catch (error) {
      console.error("Error adding document: ", error);
      // Handle the error appropriately (e.g., show an error message to the user)
      alert("An error occurred.  Please try again."); // Basic error handling
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title className="marker-font">
          <span style={{ marginRight: "20px" }}>
            <img
              src={logo}
              alt="Logo"
              className="inline-logo"
              style={{ marginLeft: "120px" }}
            />
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        {" "}
        <Card>
          {" "}
          <Card.Body>
            {!subscribed ? (
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="marker-font">
                    <p className="marker-font text-center">
                      SEC Fantasy is the world's top
                      <br />
                      College Football Fantasy App.
                    </p>
                    <p className="marker-font text-center">
                      Enter your email to be notified when
                      the 2025 season is
                      about to start!
                    </p>{" "}
                  </Form.Label>
                  <Form.Control
                    className="marker-font text-center email-input"
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                  />
                </Form.Group>
                <p className="marker-font text-center">
                  <Button variant="outline-primary" type="submit">
                    Submit
                  </Button>
                </p>
              </Form>
            ) : (
              <p className="marker-font">We'll keep you updated!</p>
            )}
          </Card.Body>
        </Card>
      </Modal.Body>
    </Modal>
  );
}

export default About;